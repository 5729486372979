import axios from "axios";

//const url = "http://192.168.0.13:8000/";
const url = "https://api.bolehhosting.com/";

let api_token = "72cdb3d83588673d06d1ba3337bb7304";

export const link = axios.create({
  baseURL: url,
  headers: { "api-token": api_token },
});
