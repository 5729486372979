import React from "react";
import Header from "../component/global/Header";
import Sidebar from "../component/global/Sidebar";
import Footer from "../component/global/Footer";
import ClientEngagementListContent from "../component/contents/ClientEngagementList";

function AuditorEngagementList() {
  return (
    <div>
      <Header />
      <Sidebar />
      <ClientEngagementListContent />
      <Footer />
    </div>
  );
}

export default AuditorEngagementList;
