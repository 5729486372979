import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ClientDashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      sessionStorage.getItem("token") === undefined ||
      sessionStorage.getItem("token") === null
    ) {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid text-right"></div>
          </section>
          <section className="content">
            <div className="row">
              <div className="col-sm-8"></div>
              <div className="col-md-4"></div>
            </div>
            <div className="row">
              <div className="col-sm-12"></div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ClientDashboard;
