import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="main-footer">
          <div className="float-right d-none d-sm-block">
            <b>Version</b> 2.0
          </div>
          <strong>
            <a href="http://www.hgkfirm.com/">HGK Firm</a>.
          </strong>{" "}
          All rights reserved.
        </footer>
      </div>
    );
  }
}
