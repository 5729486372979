//React
import React, { useState, useEffect } from "react";
import { link } from "../../../../axios/Link";
import { Table, Space, Input, Button, Tooltip, Tag } from "antd";
import { useNavigate } from "react-router-dom";

let DataProyek = [];

function AuditorEngagementListPage() {
  const navigate = useNavigate();

  //Table Proyek ======================

  const [isiTableProyek, setIsiTableProyek] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const columns = [
    {
      key: "action",
      title: "ACTION",
      dataIndex: "action",
    },
    {
      key: "nama_cust",
      title: "CLIENT NAME",
      dataIndex: "nama_cust",
      sorter: (a, b) => a.nama_cust.length - b.nama_cust.length,
    },
    {
      key: "periode_proyek",
      title: "PERIOD",
      dataIndex: "periode_proyek",
      sorter: (a, b) => a.periode_proyek.length - b.periode_proyek.length,
    },
    {
      key: "call_proyek",
      title: "CALL",
      dataIndex: "call_proyek",
      sorter: (a, b) => a.call_proyek.length - b.call_proyek.length,
    },
    {
      key: "jml_doc",
      title: "DOCUMENTS",
      dataIndex: "jml_doc",
      justifyContent: "center",
    },
    {
      key: "status",
      title: "STATUS",
      dataIndex: "status",
    },
  ];

  const id_users = sessionStorage.getItem("id_users");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const resDataProyek = await link.get("/GetProyekByUser/" + id_users);
    resDataProyek.data.map(function (d) {
      DataProyek.push({
        key: d.id_proyek,
        action: (
          <>
            <Tooltip placement="topLeft" title="View Documents">
              <button
                className="btn btn-sm bg-gradient-primary"
                onClick={() => showData(d.id_proyek, d.id_cust)}
              >
                <i className="fa fa-address-book"></i>
              </button>
            </Tooltip>
          </>
        ),
        nama_cust: d.nama_cust,
        periode_proyek: d.periode_proyek,
        call_proyek: d.call_proyek,
        tgl_mulai: d.tgl_mulai,
        tgl_akhir: d.tgl_akhir,
        jml_doc: <Tag color="geekblue">{d.jml_doc}</Tag>,
        status: (
          <>
            <Tag className="mr-1" color="green">
              New : {d.jml_process}
            </Tag>
            <Tag className="mr-1" color="orange">
              Process : {d.jml_received}
            </Tag>
            <Tag className="mr-1" color="red">
              Returned : {d.jml_return}
            </Tag>
            <Tag className="mr-1" color="purple">
              Accepted : {d.jml_clear}
            </Tag>
          </>
        ),
      });
    });
    setIsiTableProyek(DataProyek);
    DataProyek = [];
    setLoading(false);
  };

  async function fetchDataFilter(vSearch) {
    setLoading(true);

    const resDataProyek = await link
      .get("/GetProyekByUserFilter/" + id_users + "/" + vSearch)
      .catch(function (error) {
        setLoading(false);
      });

    resDataProyek.data.map(function (d) {
      DataProyek.push({
        key: d.id_proyek,
        action: (
          <>
            <Tooltip placement="topLeft" title="View Documents">
              <button
                className="btn btn-sm bg-gradient-primary"
                onClick={() => showData(d.id_proyek, d.id_cust)}
              >
                <i className="fa fa-address-book"></i> View
              </button>
            </Tooltip>
          </>
        ),
        nama_cust: d.nama_cust,
        periode_proyek: d.periode_proyek,
        call_proyek: d.call_proyek,
        tgl_mulai: d.tgl_mulai,
        tgl_akhir: d.tgl_akhir,
        jml_doc: <Tag color="geekblue">{d.jml_doc}</Tag>,
        status: (
          <>
            <Tag className="mr-1" color="green">
              New : {d.jml_process}
            </Tag>
            <Tag className="mr-1" color="orange">
              Process : {d.jml_received}
            </Tag>
            <Tag className="mr-1" color="red">
              Returned : {d.jml_return}
            </Tag>
            <Tag className="mr-1" color="purple">
              Accepted : {d.jml_clear}
            </Tag>
          </>
        ),
      });
    });

    setIsiTableProyek(DataProyek);
    DataProyek = [];
    setLoading(false);
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      fetchData();
    }
  };

  const globalSearch = () => {
    if (searchText !== "") {
      fetchDataFilter(searchText);
    }
  };

  function showData(id_proyek, id_cust) {
    //console.log(id_cust);

    sessionStorage.setItem("id_proyek", id_proyek);
    sessionStorage.setItem("clientID", id_cust);
    navigate("/AuditorClientDocs");
  }

  return (
    <div>
      <div className="card mt-2">
        <div className="card-header">
          <h3 className="card-title">ENGAGEMENT LIST</h3>
        </div>
        <div className="card-body">
          <div className="mt-2">
            <Space style={{ marginBottom: 16 }}>
              <Input
                placeholder="Search Here"
                onChange={handleSearch}
                type="text"
                allowClear
                value={searchText}
              />
              <Button
                onClick={globalSearch}
                type="btn btn-sm bg-gradient-primary"
              >
                Search
              </Button>
            </Space>
            <Table
              loading={loading}
              columns={columns}
              dataSource={isiTableProyek}
              size="small"
              pagination={true}
              scroll={{ x: 1200 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuditorEngagementListPage;
