import React from "react";
import Header from "../component/global/Header";
import Sidebar from "../component/global/Sidebar";
import Footer from "../component/global/Footer";
import ClientClientDocsContent from "../component/contents/ClientClientDocs";

function ClientClientDocs() {
  return (
    <div>
      <Header />
      <Sidebar />
      <ClientClientDocsContent />
      <Footer />
    </div>
  );
}

export default ClientClientDocs;
