import React from "react";
import Header from "../component/global/Header";
import Sidebar from "../component/global/Sidebar";
import Footer from "../component/global/Footer";
import AuditorEngagementListContent from "../component/contents/AuditorEngagementList";

function AuditorEngagementList() {
  return (
    <div>
      <Header />
      <Sidebar />
      <AuditorEngagementListContent />
      <Footer />
    </div>
  );
}

export default AuditorEngagementList;
