import React from "react";
import Header from "../component/global/Header";
import Sidebar from "../component/global/Sidebar";
import Footer from "../component/global/Footer";
import AuditorClientPICContent from "../component/contents/AuditorClientPIC";

function ClientPIC() {
  return (
    <div>
      <Header />
      <Sidebar />
      <AuditorClientPICContent />
      <Footer />
    </div>
  );
}

export default ClientPIC;
