import axios from "axios";

//const url = "http://192.168.0.13:8000/api/";
const url = "https://api.bolehhosting.com/api/";

let api_token = sessionStorage.getItem("token");

export const link = axios.create({
  baseURL: url,
  headers: { "api-token": api_token },
});
