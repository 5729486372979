//React
import React, { useState, useEffect } from "react";
import { link } from "../../../../axios/Link";
import {
  Table,
  Space,
  Input,
  Button,
  Tooltip,
  Modal,
  Form,
  notification,
  Badge,
  Upload,
  Tag,
} from "antd";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const dateFormat = "DD-MMM-YYYY";

//const { Option } = Select;

// Local Parameter ========================
let DataClientDocs = [];
let DataDocumentFiles = [];
let DeleteDocumentFile = [];
let warnaStatus = "";
let DataDocumentComm = [];
let DeleteDataComment = [];

const url = "http://192.168.0.13:8000/files/";
let urlFile = "";

function ClientClientDocsPage() {
  // Local Function Message ========================

  const [messageApi, contextHolder] = notification.useNotification();
  function msgChooseDoc() {
    messageApi.open({
      type: "warning",
      description: "Please Choose Document First",
    });
  }

  function msgSuccess() {
    messageApi.open({
      type: "success",
      description: "Data Saved",
    });
  }

  function msgDelete() {
    messageApi.open({
      type: "success",
      description: "Data Deleted",
    });
  }

  // END Local Function ========================

  // GET NAMA PROYEK
  const [namaProyek, setNamaProyek] = useState("");
  async function fetchNamaProyek(id_proyek) {
    const resDataDocumentName = await link.get(
      "/GetCustProyekNama/" + sessionStorage.getItem("id_proyek")
    );
    resDataDocumentName.data.map(function (d) {
      setNamaProyek(d.call_proyek);
    });
  }

  //Table Client Documents ======================

  const [isiTableClientDocument, setIsiTableClientDocument] = useState([]);
  const [documentName, setDocumentName] = useState(
    "Please Choose Document First"
  );
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  // CLIENT DOCUMENT COLUMN
  const columns = [
    {
      key: "nama_doc",
      title: "DOCUMENT",
      dataIndex: "nama_doc",
      sorter: (a, b) => a.nama_doc.length - b.nama_doc.length,
    },
    {
      key: "delivery_date",
      title: "REQUEST DATE",
      dataIndex: "delivery_date",
    },
    {
      key: "due_date",
      title: "DUE DATE",
      dataIndex: "due_date",
      sorter: (a, b) => a.due_date.length - b.due_date.length,
    },
    {
      key: "status",
      title: "STATUS",
      dataIndex: "status",
    },
    {
      key: "inisial",
      title: "PIC",
      dataIndex: "inisial",
    },
    {
      key: "action",
      title: "ACTION",
      dataIndex: "action",
    },
  ];

  useEffect(() => {
    fetchNamaProyek();
    fetchData();
  }, []);

  // GET DATA CLIENT DOCUMENT
  const fetchData = async () => {
    setLoading(true);
    const resDataClientDocs = await link.get(
      "/GetCustClientDocs/" + sessionStorage.getItem("id_proyek")
    );

    resDataClientDocs.data.map(function (d) {
      switch (d.status) {
        case "New":
          warnaStatus = "green";
          break;
        case "Process":
          warnaStatus = "orange";
          break;
        case "Returned":
          warnaStatus = "red";
          break;
        default:
          warnaStatus = "purple";
      }

      DataClientDocs.push({
        key: d.id_client_docs,
        id_users: d.id_users,
        id_proyek: d.id_proyek,
        id_arsip: d.id_arsip,
        id_cust: d.id_cust,
        nama_doc: d.nama_doc,
        remark: d.remark,
        delivery_date: dayjs(d.delivery_date).format(dateFormat),
        due_date: dayjs(d.due_date).format(dateFormat),
        status: (
          <Tag className="mr-1" color={warnaStatus}>
            {d.status}
          </Tag>
        ),
        inisial: (
          <Badge count={d.inisial} style={{ backgroundColor: "#FF7043" }} />
        ),
        action: (
          <>
            <Tooltip placement="topLeft" title="Choose Document">
              <button
                className="btn btn-sm bg-gradient-primary mt-1 mr-1"
                onClick={() => showData(d.id_client_docs)}
              >
                <i className="fa fa-folder-open"></i>
              </button>
            </Tooltip>
          </>
        ),
      });
    });

    setIsiTableClientDocument(DataClientDocs);
    DataClientDocs = [];
    setLoading(false);
  };

  async function fetchDataFilter(vSearch) {
    setLoading(true);
    const resDataClientDocs = await link.get(
      "/GetCustClientDocsFilter/" +
        sessionStorage.getItem("id_proyek") +
        "/" +
        vSearch
    );

    resDataClientDocs.data.map(function (d) {
      switch (d.status) {
        case "New":
          warnaStatus = "green";
          break;
        case "Process":
          warnaStatus = "orange";
          break;
        case "Returned":
          warnaStatus = "red";
          break;
        default:
          warnaStatus = "purple";
      }

      DataClientDocs.push({
        key: d.id_client_docs,
        id_users: d.id_users,
        id_proyek: d.id_proyek,
        id_arsip: d.id_arsip,
        id_cust: d.id_cust,
        nama_doc: d.nama_doc,
        remark: d.remark,
        delivery_date: dayjs(d.delivery_date).format(dateFormat),
        due_date: dayjs(d.due_date).format(dateFormat),
        status: (
          <Tag className="mr-1" color={warnaStatus}>
            {d.status}
          </Tag>
        ),
        inisial: (
          <Badge count={d.inisial} style={{ backgroundColor: "#FF7043" }} />
        ),
        action: (
          <>
            <Tooltip placement="topLeft" title="Choose Document">
              <button
                className="btn btn-sm bg-gradient-primary mt-1 mr-1"
                onClick={() => showData(d.id_client_docs)}
              >
                <i className="fa fa-folder-open"></i>
              </button>
            </Tooltip>
          </>
        ),
      });
    });

    setIsiTableClientDocument(DataClientDocs);
    DataClientDocs = [];
    setLoading(false);
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      fetchData();
    }
  };

  const globalSearch = () => {
    if (searchText !== "") {
      fetchDataFilter(searchText);
    }
  };

  // END Table Client ==========================

  // Table DOCUMENT FILES ==========================
  const [isiTableDocumentFiles, setIsiTableDocumentFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);

  function showData(id_client_docs) {
    sessionStorage.setItem("id_client_docs", id_client_docs);
    fetchDocumentName(id_client_docs);
    fetchDataDocumentFiles(id_client_docs);
    fetchDataDocumentComm(id_client_docs);
  }

  async function fetchDocumentName(id_client_docs) {
    const resDataDocumentName = await link.get(
      "/GetCustDocumentName/" + id_client_docs
    );
    resDataDocumentName.data.map(function (d) {
      setDocumentName(d.nama_doc);
    });
  }

  // DATA FILE COLUMN
  const columnsFiles = [
    {
      key: "file_name",
      title: "FILE NAME",
      dataIndex: "file_name",
      sorter: (a, b) => a.full_name.length - b.full_name.length,
    },
    {
      key: "action",
      title: "ACTION",
      dataIndex: "action",
    },
  ];

  const fetchDataDocumentFiles = async () => {
    setLoadingFiles(true);
    const resDataDocumentFiles = await link.get(
      "/GetCustDocumentFiles/" + sessionStorage.getItem("id_client_docs")
    );
    resDataDocumentFiles.data.map(function (d) {
      urlFile = url + d.file_path;
      DataDocumentFiles.push({
        key: d.id_client_docs_files,
        file_name: d.file_name,
        action: (
          <>
            <Tooltip placement="topLeft" title="Download File">
              <a href={urlFile} target="_blank">
                <button className="btn btn-sm bg-gradient-success mt-1 mr-1">
                  <i className="fa fa-download"></i>
                </button>
              </a>
            </Tooltip>
            <Tooltip placement="topLeft" title="Delete File">
              <button
                className="btn btn-sm bg-gradient-danger mt-1"
                onClick={() => deleteFile(d.id_client_docs_files)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Tooltip>
          </>
        ),
      });
    });
    setIsiTableDocumentFiles(DataDocumentFiles);
    DataDocumentFiles = [];
    setLoadingFiles(false);
  };

  // END Table DOCUMENT FILES ==========================

  // MODAL ADD FILE ==========================

  const [formAddDocumentFiles] = Form.useForm();

  const [isModalOpenAddFiles, setIsModalOpenAddFiles] = useState(false);

  function AddDocumentFile() {
    if (documentName === "Please Choose Document First") {
      msgChooseDoc();
    } else {
      formAddDocumentFiles.setFieldsValue({
        id_client_docs: sessionStorage.getItem("id_client_docs"),
        id_users: sessionStorage.getItem("id_users"),
      });
      setIsModalOpenAddFiles(true);
    }
  }

  // File Upload

  function handleCancelAddFiles() {
    setIsModalOpenAddFiles(false);
  }

  const onFinishAddFiles = (val) => {
    const formData = new FormData();
    formData.append("id_client_docs", val.id_client_docs);
    formData.append("id_users", val.id_users);
    formData.append("file_name", val.file_name);
    formData.append("file", val.upload.fileList[0].originFileObj);

    async function submit() {
      await link.post("/AddCustDocumentFiles", formData).then((res) => {
        //console.log(res);
        fetchDataDocumentFiles();
        msgSuccess();
      });
    }
    submit();
    formAddDocumentFiles.resetFields();
    setIsModalOpenAddFiles(false);
    fetchData();
  };

  // END MODAL ADD FILE ======================

  // MODAL DELETE FILE ==========================

  const [formDeleteDocumentFiles] = Form.useForm();
  const [isModalOpenDeleteFile, setIsModalOpenDeleteFile] = useState(false);

  function deleteFile(id_client_docs_files) {
    fetchDataDeleteFileById(id_client_docs_files);
    setIsModalOpenDeleteFile(true);
  }

  async function fetchDataDeleteFileById(id_client_docs_files) {
    const resDataDocumentFile = await link.get(
      "/GetCustDocumentFileById/" + id_client_docs_files
    );
    resDataDocumentFile.data.map(function (d) {
      formDeleteDocumentFiles.setFieldsValue({
        id_client_docs_files: d.id_client_docs_files,
        file_name: d.file_name,
        file_path: d.file_path,
        id_client_docs: d.id_client_docs,
      });
    });
  }

  function handleCancelDelete() {
    setIsModalOpenDeleteFile(false);
  }

  const onFinishDeleteFile = (val) => {
    DeleteDocumentFile.push({
      id_client_docs_files: val.id_client_docs_files,
      file_path: val.file_path,
      id_client_docs: val.id_client_docs,
    });
    formDeleteDocumentFiles.resetFields();
    async function submit() {
      await link
        .put("/DeleteCustDocumentFileById", DeleteDocumentFile)
        .then((res) => {
          fetchDataDocumentFiles();
          msgDelete();
        });
    }
    submit();
    DeleteDocumentFile = [];
    setIsModalOpenDeleteFile(false);
    fetchData();
  };

  // END MODAL DELETE FILE ==========================

  // DOCUMENT COMMENT ==========================

  const [isiDataComm, setIsiDataComm] = useState([]);
  const [isiComment, setIsiComment] = useState("");

  async function fetchDataDocumentComm(id_client_docs) {
    const resDataDocumentComm = await link.get(
      "/GetDocumentComments/" + id_client_docs
    );
    resDataDocumentComm.data.map(function (d) {
      DataDocumentComm.push({
        key: d.id_client_docs_comm,
        id_users: d.id_users,
        inisial: d.inisial,
        comment: d.comment,
        posisiChat:
          d.id_users === sessionStorage.getItem("id_users")
            ? "direct-chat-msg right"
            : "direct-chat-msg",
        posisiComment:
          d.id_users === sessionStorage.getItem("id_users")
            ? "direct-chat-text text-sm text-right"
            : "direct-chat-text text-sm",
        warna: d.id_users === sessionStorage.getItem("id_users") ? 1 : 2,
        delete:
          d.id_users === sessionStorage.getItem("id_users") ? (
            <Tag
              className="mt-1"
              color={"red"}
              onClick={() => deleteComm(d.id_client_docs_comm)}
            >
              Delete
            </Tag>
          ) : (
            ""
          ),
      });
    });
    setIsiDataComm(DataDocumentComm);
    DataDocumentComm = [];
  }

  async function deleteComm(id_client_docs_comm) {
    DeleteDataComment.push({
      id_client_docs_comm: id_client_docs_comm,
    });

    await link.put("/DeleteComment", DeleteDataComment).then(() => {
      fetchDataDocumentComm(sessionStorage.getItem("id_client_docs"));
    });

    DeleteDataComment = [];
  }

  function handleCommentChange(e) {
    setIsiComment(e.target.value);
  }

  function submitComment() {
    if (isiComment !== "") {
      //AddDataComment = [];

      const formData = new FormData();
      formData.append(
        "id_client_docs",
        sessionStorage.getItem("id_client_docs")
      );
      formData.append("id_users", sessionStorage.getItem("id_users"));
      formData.append("comment", isiComment);

      async function submit() {
        await link.post("/AddComment", formData).then((res) => {
          fetchDataDocumentComm(sessionStorage.getItem("id_client_docs"));
        });
      }

      submit();
      setIsiComment("");
    }
  }

  // END DOCUMENT COMMENT ==========================

  return (
    <div>
      {contextHolder}
      {/* ADD DOCUMENT FILE */}
      <Modal
        title={"ADD FILES"}
        open={isModalOpenAddFiles}
        onCancel={handleCancelAddFiles}
        footer={false}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <Form
                form={formAddDocumentFiles}
                name="control-hooks"
                onFinish={onFinishAddFiles}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
              >
                <div className="card-body">
                  <Form.Item
                    name="id_client_docs"
                    label="id_client_docs"
                    hidden={true}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="id_users"
                    label="id_users"
                    hidden={true}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="file_name"
                    label="File Name"
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="upload"
                    label="Upload File"
                    style={{ marginBottom: "0px" }}
                  >
                    <Upload maxCount={1} beforeUpload={() => false}>
                      <Button className="bg-gradient-success">
                        Click to Browse File
                      </Button>
                    </Upload>
                  </Form.Item>
                </div>
                <div className="card-footer">
                  <Form.Item
                    wrapperCol={{ offset: 8, span: 16 }}
                    style={{ marginBottom: "0px" }}
                  >
                    <Button className="bg-gradient-primary" htmlType="submit">
                      <i className="fa fa-file-medical"></i> &nbsp; Add
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
      {/* DELETE DOCUMENT FILE */}
      <Modal
        title={"DELETE FILES"}
        open={isModalOpenDeleteFile}
        onCancel={handleCancelDelete}
        footer={false}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <Form
                form={formDeleteDocumentFiles}
                name="control-hooks"
                onFinish={onFinishDeleteFile}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
              >
                <div className="card-body">
                  <label>Are you sure want to delete this file ?</label>
                  <Form.Item
                    name="id_client_docs_files"
                    label="id_client_docs_files"
                    hidden={true}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="file_path"
                    label="file_path"
                    hidden={true}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="id_client_docs"
                    label="id_client_docs"
                    hidden={true}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="file_name"
                    label="File Name"
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="card-footer">
                  <Form.Item
                    wrapperCol={{ offset: 8, span: 16 }}
                    style={{ marginBottom: "0px" }}
                  >
                    <Button className="bg-gradient-primary" htmlType="submit">
                      <i className="fa fa-file-medical"></i> &nbsp; Delete
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>

      {/* LAYOUT UTAMA */}
      <div className="card mt-2">
        <div className="card-header">
          <h3 className="card-title">CLIENT DOCUMENTS</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="card col-md-8">
              <div className="card-header">
                <h3 className="card-title">Documents List - {namaProyek}</h3>
              </div>
              <div className="card-body">
                <Space style={{ marginBottom: 16 }}>
                  <Input
                    placeholder="Search Here"
                    onChange={handleSearch}
                    type="text"
                    allowClear
                    value={searchText}
                  />
                  <Tooltip placement="topLeft" title="Click to Search">
                    <Button
                      onClick={globalSearch}
                      type="btn btn-sm bg-gradient-success"
                    >
                      <i className="fa fa-search"></i>
                    </Button>
                  </Tooltip>
                </Space>
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={isiTableClientDocument}
                  size="small"
                  pagination={true}
                  scroll={{ x: 550 }}
                />
              </div>
            </div>
            <div className="card col-md-4">
              <div className="card-header">
                <h3 className="card-title">{documentName}</h3>
              </div>
              <div className="card-body">
                <Space style={{ marginBottom: 16 }}>
                  <Tooltip placement="topLeft" title="Add Document Files">
                    <Button
                      type="btn btn-sm bg-gradient-primary"
                      onClick={AddDocumentFile}
                    >
                      <i className="fa fa-file-medical"></i>
                    </Button>
                  </Tooltip>
                </Space>
                <Table
                  loading={loadingFiles}
                  columns={columnsFiles}
                  dataSource={isiTableDocumentFiles}
                  size="small"
                  pagination={true}
                  scroll={{ x: 350 }}
                />
              </div>
              {/* CHAT */}
              <div className="row">
                <div className="col-md-12">
                  {/* DIRECT CHAT */}
                  <div className="card direct-chat direct-chat-info">
                    <div className="card-header">
                      <h3 className="card-title">Comments</h3>
                      <div className="card-tools">
                        <i className="fas fa-comments" />
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="direct-chat-messages">
                        {isiDataComm.map((row, index) => (
                          <div className={row.posisiChat} key={index}>
                            <Badge
                              className="direct-chat-img"
                              count={row.inisial}
                              style={{
                                backgroundColor:
                                  row.warna == 1 ? "#00ACC1" : "#FF5722",
                              }}
                            />
                            <div className={row.posisiComment}>
                              {row.comment}
                            </div>
                            <div className="text-sm text-right">
                              {row.delete}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="input-group">
                        <input
                          type="text"
                          name="message"
                          placeholder="Type Comment ..."
                          className="form-control"
                          onChange={handleCommentChange}
                          value={isiComment}
                        />
                        <span className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={submitComment}
                          >
                            Send
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientClientDocsPage;
