import React from "react";
import Header from "../component/global/Header";
import Sidebar from "../component/global/Sidebar";
import Footer from "../component/global/Footer";
import EngagementListContent from "../component/contents/AuditorDashboard";

function AuditorDashboard() {
  return (
    <div>
      <Header />
      <Sidebar />
      <EngagementListContent />
      <Footer />
    </div>
  );
}

export default AuditorDashboard;
