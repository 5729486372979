import Login from "./pages/Login";
import AuditorDashboard from "./pages/AuditorDashboard";
import AuditorClientPIC from "./pages/AuditorClientPIC";
import AuditorEngagementList from "./pages/AuditorEngagementList";
import AuditorClientDocs from "./pages/AuditorClientDocs";

import ClientDashboard from "./pages/ClientDashboard";
import ClientEngagementList from "./pages/ClientEngagementList";
import ClientClientDocs from "./pages/ClientClientDocs";

import Profile from "./pages/Profile";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} exact />

          <Route path="/AuditorDashboard" element={<AuditorDashboard />} />
          <Route path="/AuditorClientPIC" element={<AuditorClientPIC />} />
          <Route
            path="/AuditorEngagementList"
            element={<AuditorEngagementList />}
          />
          <Route path="/AuditorClientDocs" element={<AuditorClientDocs />} />

          <Route path="/ClientDashboard" element={<ClientDashboard />} />
          <Route
            path="/ClientEngagementList"
            element={<ClientEngagementList />}
          />
          <Route path="/ClientClientDocs" element={<ClientClientDocs />} />

          <Route path="/profile" element={<Profile />} />
          <Route path="/logout" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
