import React from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  //console.log(sessionStorage.getItem('is_client'));

  let menu;

  if (sessionStorage.getItem("is_client") === "No") {
    menu = (
      <>
        <li className="nav-item">
          <Link to="/AuditorDashboard" className="nav-link">
            <i className="nav-icon fa fa-tv" />
            <p>Dashboard</p>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/AuditorClientPIC" className="nav-link">
            <i className="nav-icon fa fa-list" />
            <p>Client PIC</p>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/AuditorEngagementList" className="nav-link">
            <i className="nav-icon fa fa-list" />
            <p>Engagement List</p>
          </Link>
        </li>
      </>
    );
  } else if (sessionStorage.getItem("is_client") === "Yes") {
    menu = (
      <>
        <li className="nav-item">
          <Link to="/ClientDashboard" className="nav-link">
            <i className="nav-icon fas fa-tachometer-alt" />
            <p>Dashboard</p>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/ClientEngagementList" className="nav-link">
            <i className="nav-icon fas fa-th-list" />
            <p>Documents</p>
          </Link>
        </li>
      </>
    );
  }

  return (
    <div>
      <aside className="main-sidebar sidebar-light-primary elevation-1">
        <a href="#" className="brand-link">
          <img src="/dist/img/logohkg.gif" alt="Logo" className="elevation-1" />
          <span className="brand-text font-weight-light">
            <strong> &nbsp; </strong>
          </span>
        </a>
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {menu}
              <li className="nav-item">
                <Link to="/profile" className="nav-link">
                  <i className="nav-icon fas fa-user" />
                  <p>Profile</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/logout" className="nav-link">
                  <i className="nav-icon fas fa-reply" />
                  <p>Logout</p>
                </Link>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
}

export default Sidebar;
