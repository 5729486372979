import React, { useState } from "react";
import { link } from "../../axios/LinkLogin";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

let pesanLogin = "";

function LoginPage() {
  sessionStorage.clear();
  localStorage.clear();
  const { register, handleSubmit, reset } = useForm();
  const navigate = useNavigate();
  const [pesan, setPesan] = useState("Sign in to start your session");

  async function login(data) {
    //console.log(data);

    const res = await link.post("/login", data).catch(function (error) {
      setPesan("Please HGK To Log Your Session");
    });

    //console.log(res.data);

    pesanLogin = res.data.pesan;
    if (pesanLogin === "login berhasil") {
      let token = await res.data.token;
      let id_users = await res.data.id_users;
      let email_kantor = await res.data.email_kantor;
      let inisial = await res.data.inisial;
      let status = await res.data.status;
      let is_client = await res.data.is_client;
      let id_cust = await res.data.id_cust;
      let full_name = await res.data.full_name;

      sessionStorage.setItem("token", token);
      sessionStorage.setItem("id_users", id_users);
      sessionStorage.setItem("email_kantor", email_kantor);
      sessionStorage.setItem("inisial", inisial);
      sessionStorage.setItem("status", status);
      sessionStorage.setItem("is_client", is_client);
      sessionStorage.setItem("id_cust", id_cust);
      sessionStorage.setItem("full_name", full_name);

      const gettoken = () => sessionStorage.getItem("token");

      if (gettoken() === "undefined" || gettoken() === "null") {
        navigate("/");
      } else {
        if (sessionStorage.getItem("is_client") === "No") {
          navigate("/AuditorDashboard");
        } else if (sessionStorage.getItem("is_client") === "Yes") {
          navigate("/ClientDashboard");
        } else {
          navigate("/");
        }
        navigate(0);
      }

      reset();
    } else if (pesanLogin === "gagal3") {
      setPesan("Wrong Password");
    } else if (pesanLogin === "gagal2") {
      setPesan("Your Account is not active");
    } else if (pesanLogin === "gagal1") {
      setPesan("Wrong Username");
    }
  }

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h4 className="text-center">HGK PORTAL</h4>
            <p className="text-center">KAP Hertanto Grace Karunawan</p>
          </div>
          <div className="card-body">
            <p className="login-box-msg">{pesan}</p>
            <form onSubmit={handleSubmit(login)}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  {...register("username", { required: true })}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  {...register("password", { required: true })}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8"></div>
                <div className="col-4">
                  <button type="submit" className="btn btn-primary btn-block">
                    Sign In
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
