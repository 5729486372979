import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ClientClientDocsPage from "./components/ClientClientDocsPage";

function ClientClientDocs() {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      sessionStorage.getItem("token") === undefined ||
      sessionStorage.getItem("token") === null
    ) {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <ClientClientDocsPage />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ClientClientDocs;
