import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileBox from './components/ProfileBox';
import JamDigital from '../Jam'

function Profile() {

  const navigate = useNavigate();
  useEffect(() => {
    if ((sessionStorage.getItem('token') === undefined) || (sessionStorage.getItem('token') === null)) {
      navigate('/');
    };
  }, []);


  return (
    <div>
      <div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid text-right">
              <JamDigital />
            </div>
          </section>
          <section className="content">
            <div className='row'>
              <div className='col-sm-6'>
                <ProfileBox />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Profile

