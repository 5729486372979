//React
import React, { useState, useEffect } from "react";
import { link } from "../../../../axios/Link";
import { Table, Tooltip, Badge, Tag } from "antd";
import { useNavigate } from "react-router-dom";

let DataProyek = [];

function ClientEngagementListPage() {
  const navigate = useNavigate();

  //Table Proyek ======================

  const [isiTableProyek, setIsiTableProyek] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      key: "action",
      title: "ACTION",
      dataIndex: "action",
    },
    {
      key: "nama_cust",
      title: "CLIENT NAME",
      dataIndex: "nama_cust",
      sorter: (a, b) => a.nama_cust.length - b.nama_cust.length,
    },
    {
      key: "periode_proyek",
      title: "PERIOD",
      dataIndex: "periode_proyek",
      sorter: (a, b) => a.periode_proyek.length - b.periode_proyek.length,
    },
    {
      key: "call_proyek",
      title: "CALL",
      dataIndex: "call_proyek",
      sorter: (a, b) => a.call_proyek.length - b.call_proyek.length,
    },
    {
      key: "jml_doc",
      title: "DOCUMENTS",
      dataIndex: "jml_doc",
      justifyContent: "center",
    },
    {
      key: "status",
      title: "STATUS",
      dataIndex: "status",
    },
  ];

  const id_cust = sessionStorage.getItem("id_cust");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const resDataProyek = await link.get("/GetCustProyek/" + id_cust);
    resDataProyek.data.map(function (d) {
      DataProyek.push({
        key: d.id_proyek,
        action: (
          <>
            <Tooltip placement="topLeft" title="View Documents">
              <button
                className="btn btn-sm bg-gradient-primary"
                onClick={() => showData(d.id_proyek, d.id_cust)}
              >
                <i className="fa fa-address-book"></i>
              </button>
            </Tooltip>
          </>
        ),
        nama_cust: d.nama_cust,
        periode_proyek: d.periode_proyek,
        call_proyek: d.call_proyek,
        jml_doc: <Tag color="geekblue">{d.jml_doc}</Tag>,
        status: (
          <>
            <Tag className="mr-1" color="green">
              New : {d.jml_new}
            </Tag>
            <Tag className="mr-1" color="orange">
              Process : {d.jml_process}
            </Tag>
            <Tag className="mr-1" color="red">
              Returned : {d.jml_returned}
            </Tag>
            <Tag className="mr-1" color="purple">
              Accepted : {d.jml_accepted}
            </Tag>
          </>
        ),
      });
    });
    setIsiTableProyek(DataProyek);
    DataProyek = [];
    setLoading(false);
  };

  function showData(id_proyek, id_cust) {
    sessionStorage.setItem("id_proyek", id_proyek);
    sessionStorage.setItem("clientID", id_cust);
    navigate("/ClientClientDocs");
  }

  return (
    <div>
      <div className="card mt-2">
        <div className="card-header">
          <h3 className="card-title">ENGAGEMENT LIST</h3>
        </div>
        <div className="card-body">
          <div className="mt-2">
            <Table
              loading={loading}
              columns={columns}
              dataSource={isiTableProyek}
              size="small"
              pagination={true}
              scroll={{ x: 1200 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientEngagementListPage;
