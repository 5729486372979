import React from "react";
import Header from "../component/global/Header";
import Sidebar from "../component/global/Sidebar";
import Footer from "../component/global/Footer";
import ClientDashboardContent from "../component/contents/ClientDashboard";

function ClientDashboard() {
  return (
    <div>
      <Header />
      <Sidebar />
      <ClientDashboardContent />
      <Footer />
    </div>
  );
}

export default ClientDashboard;
