//React
import React, { useState }  from "react";
import { link } from "../../../../axios/Link";
import { useForm } from "react-hook-form";


function ProfileBox() {

	const [profileName] = useState(sessionStorage.getItem('nama_user_id'));
	const { register, handleSubmit, reset } = useForm();
	
	let usercode = sessionStorage.getItem('usercode');
	let result = "";

	async function changePass(data) {

		const res = await link.put('/ChangeUserPass/' + usercode, data);

		result = await res.data;

		if (result === "success") {
			alert("Password Changed Successfully.");
			reset();
		} else if (result === "wrong1") {
			alert("Confirm password is not matched");
		} else if (result === "wrong3") {
			alert("Old Password is not correct");
		}
	}

	return (
		<div>
			<div className="card" >
				<div className="card-header" style={{ backgroundColor: "#0D47A1", color: "white" }}>
					<h3 className="card-title">Change Password {profileName}</h3>
				</div>
				<form onSubmit={handleSubmit(changePass)}>
					<div className="card-body">
						<div className="form-group">
							<label htmlFor="oldpassword">Old Password</label>
							<input
								className="form-control"
								type="password"
								id="oldpassword"
								placeholder="Old Password"
								{...register('oldpassword', { required: true })}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="newpassword">New Password</label>
							<input
								className="form-control"
								type="password"
								id="newpassword"
								placeholder="New Password"
								{...register('newpassword', { required: true })}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="confirmnewpassword">Confirm New Password</label>
							<input
								className="form-control"
								type="password"
								id="confirmnewpassword"
								placeholder="Confirm New Password"
								{...register('confirmnewpassword', { required: true })}
							/>
						</div>
					</div>
					<div className="card-footer">
						<button className='btn bg-gradient-primary' onClick={() => handleSubmit()} > Save</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default ProfileBox