import React from "react";
import Header from "../component/global/Header";
import Sidebar from "../component/global/Sidebar";
import Footer from "../component/global/Footer";
import AuditorClientDocsContent from "../component/contents/AuditorClientDocs";

function AuditorClientDocs() {
  return (
    <div>
      <Header />
      <Sidebar />
      <AuditorClientDocsContent />
      <Footer />
    </div>
  );
}

export default AuditorClientDocs;
