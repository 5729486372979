import React from "react";

function Header() {
  return (
    <div>
      <div>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="pushmenu"
                href="#"
                role="button"
              >
                <i className="fas fa-bars" />
              </a>
            </li>
            <li className="nav-item">
              <span className="nav-link">
                <strong>HGK CLIENT PORTAL</strong>{" "}
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
