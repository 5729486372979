import React, { useState, useEffect } from 'react';

function Jam() {
	// Jam
  const [jamDigital, setJamDigital] = useState();
	useEffect(() => {
		const interval = setInterval(() => {
			var today = new Date();
			var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
			setJamDigital(time.toString());
		}, 1000);
		return () => clearInterval(interval);
	}, []);
  return (
    <>
		<label>{jamDigital} WIB</label>
	</>
  )
}

export default Jam