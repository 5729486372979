import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuditorClientDocsPage from "./components/AuditorClientDocsPage";

function AuditorClientDocs() {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      sessionStorage.getItem("token") === undefined ||
      sessionStorage.getItem("token") === null
    ) {
      navigate("/");
    }
  }, []);

  if (sessionStorage.getItem("is_client") === "Yes") {
    console.log("");
    navigate("/");
  }

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <AuditorClientDocsPage />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AuditorClientDocs;
