import React from 'react'
import Header from "../component/global/Header";
import Sidebar from "../component/global/Sidebar";
import Footer from "../component/global/Footer";
import ProfileContent from "../component/contents/Profile";

function Profile() {
  return (
    <div>
      <Header />
      <Sidebar />
      <ProfileContent />
      <Footer />
    </div>
  )
}

export default Profile