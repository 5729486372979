//React
import React, { useState, useEffect } from "react";
import { link } from "../../../../axios/Link";
import {
  Table,
  Space,
  Input,
  Button,
  Tooltip,
  Modal,
  Form,
  Select,
  notification,
} from "antd";
const { Option } = Select;

// Local Parameter ========================
let DataClient = [];
let DataPIC = [];
let AddDataPIC = [];
let EditDataPIC = [];
let ResetDataPassPIC = [];
let DeleteDataPIC = [];

function AuditorClientPICPage() {
  // Local Function ========================

  function msgChooseClient() {
    messageApi.open({
      type: "warning",
      description: "Please Choose Client First",
    });
  }

  function msgSuccess() {
    messageApi.open({
      type: "success",
      description: "Data Saved",
    });
  }

  function msgDelete() {
    messageApi.open({
      type: "success",
      description: "Data Deleted",
    });
  }

  // END Local Function ========================

  //Table Client ======================

  const [isiTableClient, setIsiTableClient] = useState([]);
  const [idCust, setIdCust] = useState("Please ");
  const [clientName, setClientName] = useState("Please Choose Client");
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [messageApi, contextHolder] = notification.useNotification();

  const columns = [
    {
      key: "action",
      title: "ACTION",
      dataIndex: "action",
    },
    {
      key: "nama_cust",
      title: "CLIENT NAME",
      dataIndex: "nama_cust",
      sorter: (a, b) => a.nama_cust.length - b.nama_cust.length,
    },
    {
      key: "tlp_cust",
      title: "PHONE",
      dataIndex: "tlp_cust",
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const resDataClient = await link.get("/GetCustomer");
    resDataClient.data.map(function (d) {
      DataClient.push({
        key: d.id_cust,
        nama_cust: d.nama_cust,
        tlp_cust: d.tlp_cust,
        action: (
          <Tooltip placement="topLeft" title="View PIC">
            <button
              className="btn btn-sm bg-gradient-primary"
              onClick={() => showData(d.id_cust)}
            >
              <i className="fa fa-address-book"></i>
            </button>
          </Tooltip>
        ),
      });
    });
    setIsiTableClient(DataClient);
    DataClient = [];
    setLoading(false);
  };

  async function fetchDataFilter(vSearch) {
    setLoading(true);
    const resDataProyek = await link.get("/GetCustomerFilter/" + vSearch);
    resDataProyek.data.map(function (d) {
      DataClient.push({
        key: d.id_cust,
        nama_cust: d.nama_cust,
        tlp_cust: d.tlp_cust,
        action: (
          <Tooltip placement="topLeft" title="View PIC">
            <button
              className="btn btn-sm bg-gradient-primary"
              onClick={() => showData(d.id_cust)}
            >
              <i className="fa fa-address-book"></i>
            </button>
          </Tooltip>
        ),
      });
    });
    setIsiTableClient(DataClient);
    DataClient = [];
    setLoading(false);
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      fetchData();
    }
  };

  const globalSearch = () => {
    if (searchText !== "") {
      fetchDataFilter(searchText);
    }
  };

  // END Table Client ==========================

  // Table PIC ==========================

  async function fetchDataClientName(id_cust) {
    const resDataClientName = await link.get("/GetCustomerName/" + id_cust);
    resDataClientName.data.map(function (d) {
      setClientName(d.nama_cust);
    });
  }

  function showData(id_cust) {
    fetchDataPIC(id_cust);
    fetchDataClientName(id_cust);
    setIdCust(id_cust);
  }

  const [isiTablePIC, setIsiTablePIC] = useState([]);
  const [loadingPIC, setLoadingPIC] = useState(false);

  const columnsPIC = [
    {
      key: "action",
      title: "ACTION",
      dataIndex: "action",
    },
    {
      key: "full_name",
      title: "PIC NAME",
      dataIndex: "full_name",
      sorter: (a, b) => a.full_name.length - b.full_name.length,
    },
    {
      key: "hp",
      title: "PHONE",
      dataIndex: "hp",
    },
  ];

  const fetchDataPIC = async (id_cust) => {
    setLoadingPIC(true);
    const resDataPIC = await link.get("/GetCustomerPIC/" + id_cust);
    resDataPIC.data.map(function (d) {
      DataPIC.push({
        key: d.id_users,
        full_name: d.full_name,
        hp: d.hp,
        action: (
          <>
            <Tooltip placement="topLeft" title="Edit PIC">
              <button
                className="btn btn-sm bg-gradient-warning mt-1"
                onClick={() => editDataPIC(d.id_users)}
              >
                <i className="fa fa-edit"></i>
              </button>
            </Tooltip>
            &nbsp;
            <Tooltip placement="topLeft" title="Reset Password">
              <button
                className="btn btn-sm bg-gradient-success mt-1"
                onClick={() => resetPassDataPIC(d.id_users)}
              >
                <i className="fa fa-key"></i>
              </button>
            </Tooltip>
            &nbsp;
            <Tooltip placement="topLeft" title="Delete PIC">
              <button
                className="btn btn-sm bg-gradient-danger mt-1"
                onClick={() => deleteDataPIC(d.id_users)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Tooltip>
          </>
        ),
      });
    });
    setIsiTablePIC(DataPIC);
    DataPIC = [];
    setLoadingPIC(false);
  };

  // END Table PIC ==========================

  // Modal ADD PIC ==========================
  const [formAdd] = Form.useForm();

  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);

  function handleAddPIC() {
    if (clientName === "Please Choose Client") {
      //console.log(clientName);
      msgChooseClient();
    } else {
      setIsModalOpenAdd(true);
    }
  }

  const handleCancel = () => {
    formAdd.resetFields();
    setIsModalOpenAdd(false);
  };

  const onFinishAdd = (val) => {
    AddDataPIC.push({
      id_cust: idCust,
      full_name: val.full_name,
      inisial: val.inisial,
      hp: val.hp,
      email_kantor: val.email_kantor,
      password: val.password,
      is_aktif: val.is_aktif,
      id_users_manager: sessionStorage.getItem("id_users"),
    });

    async function submit() {
      await link.post("/AddCustomerPIC", AddDataPIC).then((res) => {
        console.log(res);
        fetchDataPIC(idCust);
        msgSuccess();
      });
    }
    submit();

    formAdd.resetFields();
    AddDataPIC = [];
    setIsModalOpenAdd(false);
  };

  // END MODAL ADD PIC ======================

  // MODAL EDIT PIC ==========================
  const [formEdit] = Form.useForm();

  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);

  function editDataPIC(id_users) {
    fetchDataPICById(id_users);
    setIsModalOpenEdit(true);
  }

  const handleModalEditCancel = () => {
    setIsModalOpenEdit(false);
  };

  async function fetchDataPICById(id_users) {
    const resDataPIC = await link.get("/GetCustPICById/" + id_users);
    resDataPIC.data.map(function (d) {
      formEdit.setFieldsValue({
        id_users: d.id_users,
        full_name: d.full_name,
        inisial: d.inisial,
        hp: d.hp,
        email_kantor: d.email_kantor,
        is_aktif: d.is_aktif,
      });
    });
  }

  const onFinishEdit = (val) => {
    EditDataPIC.push({
      id_users: val.id_users,
      full_name: val.full_name,
      inisial: val.inisial,
      hp: val.hp,
      email_kantor: val.email_kantor,
      is_aktif: val.is_aktif,
    });

    formEdit.resetFields();

    async function submit() {
      await link.put("/EditCustPICById", EditDataPIC).then((res) => {
        fetchDataPIC(idCust);
        msgSuccess();
      });
    }
    submit();
    EditDataPIC = [];
    setIsModalOpenEdit(false);
  };

  // END MODAL EDIT PIC ======================

  // MODAL RESET PASSWORD PIC ==========================
  const [formResetPass] = Form.useForm();

  const [isModalOpenResetPass, setIsModalOpenResetPass] = useState(false);

  const handleModalResetPassCancel = () => {
    setIsModalOpenResetPass(false);
  };

  async function fetchDataResetPassPICById(id_users) {
    const resDataPIC = await link.get("/GetCustPICById/" + id_users);
    resDataPIC.data.map(function (d) {
      formResetPass.setFieldsValue({
        id_users: d.id_users,
        full_name: d.full_name,
      });
    });
  }

  function resetPassDataPIC(id_users) {
    fetchDataResetPassPICById(id_users);
    setIsModalOpenResetPass(true);
  }

  const onFinishResetPass = (val) => {
    ResetDataPassPIC.push({
      id_users: val.id_users,
      password: val.password,
    });

    formResetPass.resetFields();

    async function submit() {
      await link.put("/ResetCustPICPassword", ResetDataPassPIC).then((res) => {
        fetchDataPIC(idCust);
        msgSuccess();
      });
    }

    submit();
    ResetDataPassPIC = [];
    setIsModalOpenResetPass(false);
  };

  // END MODAL RESET PASSWORD PIC ======================

  // MODAL DELETE PIC ==========================
  const [formDelete] = Form.useForm();

  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);

  function deleteDataPIC(id_users) {
    fetchDataDeletePICById(id_users);
    setIsModalOpenDelete(true);
  }

  function handleCancelDelete() {
    setIsModalOpenDelete(false);
  }

  async function fetchDataDeletePICById(id_users) {
    const resDataPIC = await link.get("/GetCustPICById/" + id_users);
    resDataPIC.data.map(function (d) {
      formDelete.setFieldsValue({
        id_users: d.id_users,
        full_name: d.full_name,
      });
    });
  }

  const onFinishDelete = (val) => {
    DeleteDataPIC.push({
      id_users: val.id_users,
    });

    formDelete.resetFields();

    async function submit() {
      await link.put("/DeleteUserPIC", DeleteDataPIC).then((res) => {
        fetchDataPIC(idCust);
        msgDelete();
      });
    }

    submit();
    DeleteDataPIC = [];
    setIsModalOpenDelete(false);
  };

  // END MODAL DELETE PIC ======================

  return (
    <div>
      {contextHolder}
      {/* ADD PIC */}
      <Modal
        title={"ADD PIC"}
        open={isModalOpenAdd}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <Form
                form={formAdd}
                name="control-hooks"
                onFinish={onFinishAdd}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
              >
                <div className="card-body">
                  <Form.Item
                    name="full_name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "NAME is required",
                      },
                    ]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="inisial"
                    label="Inisial"
                    rules={[
                      {
                        required: true,
                        message: "Inisial is required",
                      },
                    ]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="hp"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Phone Number is required",
                      },
                    ]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="email_kantor"
                    label="Email/Username"
                    rules={[
                      {
                        required: true,
                        message: "Email is required",
                      },
                    ]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Password is required",
                      },
                    ]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="is_aktif"
                    label="Active"
                    rules={[
                      {
                        required: true,
                        message: "Active is required",
                      },
                    ]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Select placeholder="is active">
                      <Option value="y">Yes</Option>
                      <Option value="n">No</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="card-footer">
                  <Form.Item
                    wrapperCol={{ offset: 8, span: 16 }}
                    style={{ marginBottom: "0px" }}
                  >
                    <Button className="bg-gradient-primary" htmlType="submit">
                      <i className="fa fa-user-plus"></i> &nbsp; Add
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>

      {/* EDIT PIC */}
      <Modal
        title={"EDIT PIC"}
        open={isModalOpenEdit}
        onCancel={handleModalEditCancel}
        footer={false}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <Form
                form={formEdit}
                name="control-hooks"
                onFinish={onFinishEdit}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
              >
                <div className="card-body">
                  <Form.Item name="id_users" label="id_users" hidden={true}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="full_name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Name is required",
                      },
                    ]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="inisial"
                    label="Inisial"
                    rules={[
                      {
                        required: true,
                        message: "Inisial is required",
                      },
                    ]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="hp"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Phone Number is required",
                      },
                    ]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="email_kantor"
                    label="Email/Username"
                    rules={[
                      {
                        required: true,
                        message: "Email is required",
                      },
                    ]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="is_aktif"
                    label="Active"
                    rules={[
                      {
                        required: true,
                        message: "Active is required",
                      },
                    ]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Select placeholder="is active">
                      <Option value="y">Yes</Option>
                      <Option value="n">No</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="card-footer">
                  <Form.Item
                    wrapperCol={{ offset: 8, span: 16 }}
                    style={{ marginBottom: "0px" }}
                  >
                    <Button className="bg-gradient-warning" htmlType="submit">
                      <i className="fa fa-edit"></i> &nbsp; Edit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>

      {/* RESET PASSWORD PIC */}
      <Modal
        title={"RESET PASSWORD"}
        open={isModalOpenResetPass}
        onCancel={handleModalResetPassCancel}
        footer={false}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <Form
                form={formResetPass}
                name="control-hooks"
                onFinish={onFinishResetPass}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
              >
                <div className="card-body">
                  <Form.Item name="id_users" label="id_users" hidden={true}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="full_name"
                    label="NAME"
                    style={{ marginBottom: "0px" }}
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="New Password"
                    rules={[
                      {
                        required: true,
                        message: "New Password is required",
                      },
                    ]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input.Password />
                  </Form.Item>
                </div>
                <div className="card-footer">
                  <Form.Item
                    wrapperCol={{ offset: 8, span: 16 }}
                    style={{ marginBottom: "0px" }}
                  >
                    <Button className="bg-gradient-success" htmlType="submit">
                      <i className="fa fa-edit"></i> &nbsp; Reset
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>

      {/* DELETE PIC */}
      <Modal
        title={"DELETE PIC"}
        open={isModalOpenDelete}
        onCancel={handleCancelDelete}
        footer={false}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <Form
                form={formDelete}
                name="control-hooks"
                onFinish={onFinishDelete}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
              >
                <div className="card-body">
                  <label>Are you sure want to delete this user ?</label>
                  <Form.Item name="id_users" label="id_users" hidden={true}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="full_name"
                    label="NAME"
                    style={{ marginBottom: "0px" }}
                  >
                    <Input disabled />
                  </Form.Item>
                </div>
                <div className="card-footer">
                  <Form.Item
                    wrapperCol={{ span: 16 }}
                    style={{ marginBottom: "0px" }}
                  >
                    <Button className="bg-gradient-danger" htmlType="submit">
                      <i className="fa fa-trash"></i> &nbsp; Delete
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      className="btn-outline-primary"
                      onClick={handleCancelDelete}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>

      {/* LAYOUT UTAMA */}
      <div className="card mt-2">
        <div className="card-header">
          <h3 className="card-title">CLIENT CONTACT PERSON</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="card col-md-7">
              <div className="card-header">
                <h3 className="card-title">CLIENT LIST</h3>
              </div>
              <div className="card-body">
                <Space style={{ marginBottom: 16 }}>
                  <Input
                    placeholder="Search Here"
                    onChange={handleSearch}
                    type="text"
                    allowClear
                    value={searchText}
                  />
                  <Tooltip placement="topLeft" title="Click to Search">
                    <Button
                      onClick={globalSearch}
                      type="btn btn-sm bg-gradient-success"
                    >
                      <i className="fa fa-search"></i>
                    </Button>
                  </Tooltip>
                </Space>
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={isiTableClient}
                  size="small"
                  pagination={true}
                  scroll={{ x: 550 }}
                />
              </div>
            </div>
            <div className="card col-md-5">
              <div className="card-header">
                <h3 className="card-title">PIC - {clientName}</h3>
              </div>
              <div className="card-body">
                <Space style={{ marginBottom: 16 }}>
                  <Tooltip placement="topLeft" title="Add PIC">
                    <Button
                      type="btn btn-sm bg-gradient-primary"
                      onClick={handleAddPIC}
                    >
                      <i className="fa fa-user-plus"></i>
                    </Button>
                  </Tooltip>
                </Space>
                <Table
                  loading={loadingPIC}
                  columns={columnsPIC}
                  dataSource={isiTablePIC}
                  size="small"
                  pagination={true}
                  scroll={{ x: 350 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuditorClientPICPage;
