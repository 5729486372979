import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuditorClientPICPage from "./components/AuditorClientPICPage";

function AuditorClientPIC() {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      sessionStorage.getItem("token") === undefined ||
      sessionStorage.getItem("token") === null
    ) {
      navigate("/");
    }
  }, []);

  if (sessionStorage.getItem("is_client") === "Yes") {
    console.log("");
    navigate("/");
  }

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <AuditorClientPICPage />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AuditorClientPIC;
